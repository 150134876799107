function drag_menu(item_id, default_value, callback) {
	this.main=document.getElementById(item_id);
	this._currentIndex = default_value;
	this.callback=callback;
	this.init();
}

drag_menu.prototype.init = function() {

	this._wrapper = this.main.querySelector('.slider-range__wrapper');
	this._draggable = this.main.querySelector('.slider-range__button');
	this._spots = Array.from(this.main.querySelectorAll('.line'));

	this._isDragging = false;
	this._isMoving = false;
	this._xo = 0;

	this._moveIndex = this._currentIndex;
	this._moveIndexCache = this._currentIndex;

	this._wrapper.addEventListener('touchend', this._onWrapperClick.bind(this));
	this._wrapper.addEventListener('click', this._onWrapperClick.bind(this));
	this._draggable.addEventListener('mousedown', this._onMouseDown.bind(this));
	this._draggable.addEventListener('touchstart', this._onMouseDown.bind(this));
	document.addEventListener('mousemove', this._onMouseMove.bind(this));
	document.addEventListener('touchmove', this._onMouseMove.bind(this), { passive: false });
	document.addEventListener('mouseup', this._onMouseUp.bind(this));
	document.addEventListener('touchend', this._onMouseUp.bind(this));
	window.addEventListener('resize', this._onResize.bind(this));
	
	this._gotoSpot(this._currentIndex);
}

drag_menu.prototype._onWrapperClick = function(e) {
	var self=this;

	this._isDragging=true;
	this._onMouseMove(e);
	this._onMouseUp(e);
	this._isDragging=false;
}

drag_menu.prototype._onMouseDown = function(e) {
	if(typeof e.clientX=='number'){
		e.preventDefault();
	}
	if(!this._isDragging){
		this._draggable.classList.add('slider-range__button--active');
		
		this._isDragging = true;
		if(typeof e.clientX=='number'){
			this._xo = e.clientX - this._draggable.offsetLeft - this._wrapper.offsetLeft;
		}else{
			this._xo = e.touches[0].pageX - this._draggable.offsetLeft - this._wrapper.offsetLeft;
		}
 	}
}

drag_menu.prototype._onMouseMove = function(e) {
	var self=this;
	if(this._isDragging){
		e.preventDefault();
		this._isMoving = true;

		if(typeof e.clientX=='number'){
			var x = e.clientX - this._wrapper.offsetLeft - this._xo;
		}else if (typeof e.changedTouches=='object' && e.changedTouches.length>0){
			var x = e.changedTouches[0].pageX - this._wrapper.offsetLeft - this._xo;
		}else if (typeof e.touches=='object' && e.touches.length>0){
			var x = e.touches[0].pageX - this._wrapper.offsetLeft - this._xo;
		}else{
			return false;
		}

		if(x < 0){
			x = 0;
		}else if(x > this._wrapper.offsetWidth + this._draggable.offsetWidth/2){
			x = this._wrapper.offsetWidth + this._draggable.offsetWidth/2;
		}

		
		this._draggable.style.left = x + 'px';

		this._moveIndex=this._getIndex();
		if(this._moveIndex != this._moveIndexCache){
			this._moveIndexCache=this._moveIndex;
			self.callback(self, this._moveIndex);
		}
		
		
	} else {
		this._isMoving = false;
	}
}

drag_menu.prototype._getIndex = function(e) {
	var range = this._wrapper.offsetWidth - this._draggable.offsetWidth/2;
	var unit = Math.floor(range / (this._spots.length - 1));
	
	var index = Math.floor((this._draggable.offsetLeft + this._draggable.offsetWidth/2)/unit);

	if(index<0){
		index=0;
	}else if(index>=this._spots.length){
		index=this._spots.length-1;
	}
	return index;
}

drag_menu.prototype._onMouseUp = function(e) {
	var self=this;
	if(this._isDragging){
		self._isDragging = false;
		this._draggable.classList.remove('slider-range__button--active');

		var index=this._getIndex();
		
		this._gotoSpot(index);
	}
}
    
drag_menu.prototype._gotoSpot = function(index) {
	var self=this;
	
	this._currentIndex=index;
	this._draggable.classList.add('animating');
	this._draggable.style.left = this._spots[index].offsetLeft + 'px';

	setTimeout(function(){
		self._draggable.classList.remove('animating');
		self.callback(self, index);
	}, 150);
}

drag_menu.prototype._onResize = function(e) {
	this._draggable.style.left = this._spots[this._currentIndex].offsetLeft + 'px';
}



