function quotes_answer() {
	this.position=1;
	this.cant=0;
	this.loading=false;
	this.quotes_result_id='';
};

quotes_answer.prototype.init = function() {
	var self=this;
	
	//$('body').css('overscroll-behavior-x', 'none');
	
	//tracker('event', {'event_category': 'Mi Candidato Ideal', 'event_label': document.location.href}, 'Comenzar');
	trackerEvent('Mi Candidato Ideal', 'Comenzar', document.location.href);

	self.cant=$('#answersBox .answersItem').length;
	self.showProgress();
	
	$('#answersBox .answersItem:first').addClass('opinion--active').show();
	$('#answersActions .prev').addClass('navigation-bottom__link--disabled');

	$('#answersBox .answersItem input').on('input', function(){
		var dom=$(this).closest('.answersItem').find('.answersScale');
		dom.find('.scale').removeClass('opinion__posture--active').hide();
		dom.find('.scale').eq($(this).val()-1).addClass('opinion__posture--active').show();
	});
	$('#answersBox .answersItem input').each(function(){
		$(this).trigger('input');
	});
	
	$('#answersActions .prev').on('click', function(evt){
		evt.preventDefault();

		if(self.loading){
			return false;
		}
		self.loading=true;
	
		if(self.position<=self.cant){
			$('html,body').animate({scrollTop:0},500);
			main.ajax({
				'url': baseUrl+'micandidatoideal/quotes/answer/'+self.quotes_result_id+'/'+$('.answersItem.opinion--active').prev().data('id'),
				'method': 'DELETE',
				'success': function(res){
					self.loading=false;
					if(typeof res.error=='boolean' && !res.error){
						self.quotes_result_id=res.quotes_result_id;
						var dom=$('#answersBox .answersItem.opinion--active');
						dom.removeClass('opinion--active').hide();
						dom.prev().addClass('opinion--active').show();

						self.position--;
						if(self.position==1){
							$('#answersActions .prev').addClass('navigation-bottom__link--disabled');
						}

						self.showProgress();
					}
				}
			});
		
		}
	});
	
	$('#answersActions .next').on('click', function(evt){
		evt.preventDefault();

		if(self.loading){
			return false;
		}
		self.loading=true;
	
		if(self.position<=self.cant){
			$('html,body').animate({scrollTop:0},500);
			main.ajax({
				'url': baseUrl+'micandidatoideal/quotes/answer',
				'data': {
					quote_id:$('.answersItem.opinion--active').data('id'),
					quote_scale_id:$('.answersItem.opinion--active .answersScale .opinion__posture--active').data('id'),
					cant_quote_group:self.cant,
					quotes_result_id:self.quotes_result_id
				},
				'success': function(res){
					self.loading=false;
					if(typeof res.error=='boolean' && !res.error){
						if(self.position<self.cant){
							self.quotes_result_id=res.quotes_result_id;
							var oldDom=$('#answersBox .answersItem.opinion--active');
							var newDom=oldDom.next();
							oldDom.removeClass('opinion--active').hide();
							
							newDom.addClass('opinion--active').show();

							var obj=newDom.find('.slider-range').data('drag');
							obj._gotoSpot(obj._currentIndex);

							self.position++;
							$('#answersActions .prev').removeClass('navigation-bottom__link--disabled');

							//tracker('event', {'event_label': 'quote_'+oldDom.data('id'), 'value':oldDom.find('.scale.opinion__posture--active').data('event-label')});
							//tracker('event', {'event_category': 'Mi Candidato Ideal', 'event_label':'Quote '+oldDom.data('id')+' '+oldDom.find('.scale.opinion__posture--active').data('event-label')}, 'User Answer');
							trackerEvent('Mi Candidato Ideal', 'User Answer', 'Quote '+oldDom.data('id')+' '+oldDom.find('.scale.opinion__posture--active').data('event-label'));

							self.showProgress();
						}else{
							document.location=baseUrl+'micandidatoideal/result/'+res.hash;
						}
					}
				}
			});
		
		}
	});
};

quotes_answer.prototype.onChange = function(obj, value) {
//	console.log(obj);
//	console.log(value);
	var dom=$(obj.main).closest('.answersItem').find('.answersScale');
	dom.find('.scale').removeClass('opinion__posture--active').hide();
	dom.find('.scale').eq(value).addClass('opinion__posture--active').show();
}

quotes_answer.prototype.showProgress = function() {
	var self=this;
	
	var percent=(self.position*100/self.cant);
	$('#answersCount').html(self.position+'/'+self.cant);
	$('#answersProgress').css('width', percent+'%');
	
	$('.progress__circle-progress').css('stroke-dashoffset', 226-(percent*226/100));
};

window.quotes_answer = new quotes_answer();
