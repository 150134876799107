function main() {
	this.ajax_loading=false;
	this.init();
}

function tracker(type, obj, event_action){
	if(typeof gtag=='undefined'){
		console.log(type, obj, event_action);
	}else{
		if(type=='page'){
			var trackers = window.ga.getAll();
			for (var i = 0; i < trackers.length; i++){
				var id = trackers[i].get('trackingId');

				if(typeof id=='string' && id!=''){
					gtag('config', id, obj);
				}
			}
		}else{
			gtag('event', event_action, obj);
		}
	}
}

var gaKey=false;
var gaCache=[];
function trackerEvent(eventCategory, eventAction, eventLabel, eventValue){
	if(!gaKey && typeof gaData=='object'){
		gaKey=Object.keys(gaData)[0];
		ga('create', gaKey, 'auto');
	}
	if(!gaKey){
		gaCache.push({'eventCategory': eventCategory, 'eventAction': eventAction, 'eventLabel': eventLabel, 'eventValue': eventValue});
		console.log(eventCategory, eventAction, eventLabel, eventValue);
	}else{
		if(gaCache.length>0){
			for(i in gaCache){
				ga('send', 'event', gaCache[i].eventCategory, gaCache[i].eventAction, gaCache[i].eventLabel, gaCache[i].eventValue);
			}
		}
		ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
	}
}

main.prototype.init = function() {
	// Wow animations
	var wow = new WOW({
		boxClass:     'wow',     
		animateClass: 'anim',
		offset:       50,       
		mobile:       true,      
		live:         true 
	});
	
	new WOW().init();
	
	this.trackEvents();
	
	$('.readMoreNews').on('click', function(evt){
		var dom=$(this);
		evt.preventDefault();
		main.ajax({
			'url': baseUrl+'ajax/noticias/'+$(this).attr('data-page'),
			'method': 'GET',
			'success': function(res){
				self.loading=false;
				var actualPosition=$(document).scrollTop();
				$('#newsList').append(res);
				$(document).scrollTop(actualPosition);
				
				dom.attr('data-page', dom.attr('data-page')*1+1);
				
				if(res==''){
					dom.hide();
				}
			}
		});
	});
}

main.prototype.trackEvents = function(data) {
	$('.eventNews').on('click', function(){
		trackerEvent('Home', 'Noticias', $(this).attr('href'));
	});
	
	$('.eventCandidates').on('click', function(){
		trackerEvent('Home', 'Perfil de Candidatos', $(this).attr('href'));
	});
	
	$('.eventElecciones').on('click', function(){
		trackerEvent('Header', 'Logo Elecciones', document.location.href);
	});

	$('.eventGotoQuotes').on('click', function(){
		trackerEvent('Home', 'Mi Candidato Ideal', $(this).attr('href'));
	});

	$('.eventNewsDetail').on('click', function(){
		trackerEvent('Noticias', 'Ver Historia completa', $(this).attr('href'));
	});

	$('.eventShareCandidate ').on('click', function(){
		trackerEvent('Perfil de Candidatos', $(this).closest('.card-person').attr('candidate'), $(this).attr('href'));
	});

	$('.eventFooterVote').on('click', function(){
		trackerEvent('Foooter', 'Donde Votar', $(this).attr('href'));
	});

	$('.eventFooterPaper').on('click', function(){
		trackerEvent('Foooter', 'Como es la papeleta', $(this).attr('href'));
	});

	$('.eventFooterVideos').on('click', function(){
		trackerEvent('Foooter', 'Videos', $(this).attr('href'));
	});

	$('.eventFooterHistory').on('click', function(){
		trackerEvent('Foooter', 'Historia Electoral', $(this).attr('href'));
	});

	$('.eventFooterCandidates').on('click', function(){
		trackerEvent('Foooter', 'Otras Candidaturas', $(this).attr('href'));
	});

	$('.eventFooterFacebook').on('click', function(){
		trackerEvent('Foooter', 'Facebook Icon', $(this).attr('href'));
	});

	$('.eventFooterTwitter').on('click', function(){
		trackerEvent('Foooter', 'Twitter Icon', $(this).attr('href'));
	});
}
	
main.prototype.showMessage = function(data) {
	if(typeof data.dom=='object'){
		data.dom.find('.errorMessage').html(data.message);
		data.dom.find('.errorMessage').show();
	}
}

main.prototype.ajax = function(args) {
	var self=this;
	
	if(self.ajax_loading){
		return false;
	}
	self.ajax_loading=true;
	var baseArgs={
		type: 'POST',
		complete: function(xhr){
			self.ajax_loading=false;
			if(typeof args.dom=='object'){
				args.dom.find('.loading').removeClass('loading');
			}
		},
		error: function(xhr){
			var response=JSON.parse(xhr.responseText);
			if(typeof response=='object' && typeof response.error_message=='string' && response.error_message!=''){
				self.showMessage({dom:args.dom, message: response.error_message});
			}
		}
	};
	var params=baseArgs;
	for(var i in args){
		params[i]=args[i];
	}
	//var params={...baseArgs, ...args};

	$.ajax(params);
}

window.main = new main();
