function result() {
};

result.prototype.init = function() {
	var self=this;
	
	// Slider candidates
	var slider_candidates = tns({
		container: '#slider_candidates',
		nav: false,
		controls: false,
		mouseDrag: true,
		edgePadding: 30,
		speed: 500,
		loop: false,
		autoplay: false,
		autoplayButtonOutput: false,
		autoplayTimeout: 2000,
		slideBy: 1,
		preventScrollOnTouch: 'auto',
		swipeAngle: 45,
		center: true,
		items: 1,
		gutter: 10,
		responsive: {
			768: {
					center: false,
					items: 2,
			},
			1100: {
				disable: true,
			},
		}
	});
	
	$('.trackResultDetail').on('click', function(){
		//tracker('event', {'event_category': 'Mi Candidato Ideal', 'event_label': $(this).closest('.card-person').find('.card-person__name').html()}, 'Conoce Más');
		trackerEvent('Mi Candidato Ideal', 'Conoce Más', $(this).closest('.card-person').find('.card-person__name').html());
	});
	
	$('.trackShare').on('click', function(){
		//tracker('event', {'event_category': 'Mi Candidato Ideal', 'event_label': $(this).attr('href')}, 'Compartir Experiencia');
		trackerEvent('Mi Candidato Ideal', 'Compartir Experiencia', $(this).attr('href'));
	});
};

result.prototype.initDetail = function() {
	$('.trackQuoteNews').on('click', function(){
		//tracker('event', {'event_category': 'Mi Candidato Ideal', 'event_label': $(this).attr('href')}, 'Conoce mas sobre el tema');
		trackerEvent('Mi Candidato Ideal', 'Conoce mas sobre el tema', $(this).attr('href'));
	});

};

window.result = new result();
